import ProductEvents from 'analytics/ProductEvents';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import get from 'lodash/get';
import { LoggerFactory } from 'logger';
import Service from 'model/Service';
import React from 'react';
import { Map as LeafletMap, Marker, TileLayer, Tooltip, ZoomControl } from 'react-leaflet';
import withAddLocation from '../hocs/withAddLocation';
import withAddMetro from '../hocs/withAddMetro';
import { withDisableServiceRegion, withEnableServiceRegion } from '../InfoPanel/children/ServiceRegionsView';
import ActiveMetro from './ActiveMetro';
import Clusterer from './Clusterer';
import ContextMenu from './ContextMenu';
import DirectConnection from './DirectConnection';
import ExportButton from './ExportButton';
import { getIcon } from './icons';
import LayersMenu from './LayersMenu';
import MarkerGroup from './MarkerGroup';
import MetroConnections from './MetroConnections';
import PlatformEquinixOverlay from './PlatformEquinixOverlay';
import PhaseLocation from './PhaseLocation';
import './ProjectMap.scss';
import ServiceRegionMarker from './ServiceRegionMarker';
import { getLocations, getServiceRegions } from './utils';
import filterMetrosByType from 'utils/filterMetrosByType';
import { dismissAllToasts } from 'utils/Toast';
import cn from 'classnames';
import MarketplaceButton from './MarketplaceButton';
import MarketplaceLayer from './MarketplaceLayer';
import { getMapStyle, getMapStyleUrl, setMapStyle } from './helpers/storeMapStyle';

const ConnectedServiceRegionMarker = withEnableServiceRegion(withDisableServiceRegion(ServiceRegionMarker));

const defaultBounds = new L.LatLngBounds([
  [47.613, -122.4821], // Seattle
  [60.1102, 24.7385], // Helsinki
  [-37.9716, 144.7729], // Melbourne
  [-27.3812, 152.713], // Brisbane
]);

// Empty map config
const defaultMapConfig = {
  maxZoom: 7,
  duration: 0.8,
  padding: [30, 30],
};

class ProjectMap extends React.Component {
  constructor(props) {
    super(props);
    this._logger = LoggerFactory.getLogger(this);
    this.state = {
      mapUrl: getMapStyleUrl(getMapStyle()),
      selectedMapStyle: getMapStyle(),
    };
  }

  changeMapStyle = (mapStyle) => {
    setMapStyle(mapStyle);
    this.setState({ selectedMapStyle: mapStyle, mapUrl: getMapStyleUrl(mapStyle) });
  };

  componentDidMount() {
    if (this.map && this.map.leafletElement) {
      const bounds = this.getBoundsFromProps(this.props);
      this.map.leafletElement.createPane('tooltipPane').style.zIndex = 1800;
      if (bounds) {
        this.map.leafletElement.fitBounds(bounds, {
          maxZoom: 10,
          duration: 0.8,
          padding: [30, 30],
        });
      } else {
        this.map.leafletElement.fitBounds(defaultBounds, defaultMapConfig);
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { selected, zoom } = this.props;

    // we changed the selection
    const mapConfig = {
      maxZoom: 10,
      duration: 0.5,
      paddingTopLeft: [30, 30],
      paddingBottomRight: [360, 30],
    };

    if (
      this.map &&
      ((!selected && nextProps.selected) || (selected && !nextProps.selected) || (selected && nextProps.selected && !selected.id.equals(nextProps.selected.id)))
    ) {
      this.map.leafletElement.setMaxBounds([
        [90, -360],
        [-90, 360],
      ]);
      if (nextProps.selected && nextProps.selected.coordinates) {
        // We selested a location or a metro
        this.map.leafletElement.flyToBounds([nextProps.selected.coordinates.toLeaflet()], mapConfig);
      } else if (get(nextProps, 'selected.locations')) {
        // We selected a group
        const coordinates = nextProps.selected.locations.map((l) => l.coordinates.toLeaflet());
        if (coordinates.length) {
          // if the group isn't empty, move the map to show all locations
          const bounds = new L.LatLngBounds(coordinates);
          this.map.leafletElement.flyToBounds(bounds, mapConfig);
        }
      } else if (get(nextProps, 'selected.metroId.value')) {
        // We selected a service
        const activeMetro = nextProps.phase.metros.find((metro) => metro.id.value === nextProps.selected.metroId.value);
        const bounds = [activeMetro.coordinates.toLeaflet()];
        this.map.leafletElement.flyToBounds(bounds, mapConfig);
      } else {
        // we de-selected the last element, re-size the map to the whole net
        this.map.leafletElement.setMaxBounds([
          [90, -180],
          [-90, 180],
        ]);
        let bounds = this.getBoundsFromProps(nextProps) || defaultBounds;
        this.map.leafletElement.flyToBounds(bounds, {
          ...mapConfig,
          paddingTopLeft: [30, 30],
        });
      }
    }

    if (
      this.map &&
      ((!zoom && nextProps.zoom) || (zoom && !nextProps.zoom) || (zoom && nextProps.zoom && !zoom.coordinates.equals(nextProps.zoom.coordinates)))
    ) {
      this.map.leafletElement.flyToBounds([nextProps.zoom.coordinates.toLeaflet()], mapConfig);
    }
  }

  getBoundsFromProps = (props) => {
    const { phase } = props;
    const objectsInMetros = phase.metros.reduce((objects, metro) => {
      return [...objects, ...metro.locations, metro];
    }, []);
    const objectsInGroups = phase.groups.reduce((objects, group) => {
      return [...objects, ...group.locations];
    }, []);
    const objects = [...objectsInMetros, ...objectsInGroups, ...phase.unconnectedLocations];
    if (objects.length <= 0) {
      return null;
    }
    const positions = objects.map((o) => o.position || o.coordinates.toLeaflet());
    return new L.LatLngBounds(positions);
  };

  _onSelect = (type, selectedItem) => {
    const { isEditing, onSelect, onClose } = this.props;
    if (!isEditing) return;
    onClose();
    onSelect(type, selectedItem);
  };

  _addMetro = async (phaseId, metro) => {
    const { projectId, addMetro, isEditing } = this.props;
    if (!isEditing) return;

    this._onSelect();
    await addMetro(projectId, phaseId, metro);
    dismissAllToasts();
    ProductEvents.metroAdded(metro.name);
  };

  _contextMenu = (e) => {
    if (this.props.platform) return;
    const { isEditing } = this.props;
    if (!isEditing) return;
    this.setState({ contextMenuPosition: e.latlng });
  };

  _contextMenuClose = () => {
    this.setState({ contextMenuPosition: null });
  };

  _createLocation = (type, position) => async () => {
    this.setState({ contextMenuPosition: null });
    const { phase, projectId, addLocation } = this.props;
    const query = `lat=${position.lat}&lon=${position.lng}&format=json`;
    const response = await fetch(`https://nominatim.openstreetmap.org/reverse?${query}`);
    const address = await response.json();
    this._logger.debug(`Adding location via right-click on the map.\nAddress: ${address.display_name}`);
    await addLocation(projectId, phase.id, type, address, position);
    dismissAllToasts();
    ProductEvents.locationAdded(type.toString());
  };

  _onManageMetros = () => {
    const { onManageMetros } = this.props;
    this.setState({ contextMenuPosition: null });
    onManageMetros();
  };

  _onViewportChange = (viewport) => {
    let iconSize = 1;
    if (viewport.zoom < 5) iconSize = 0.9;
    if (viewport.zoom < 4.5) iconSize = 0.8;
    if (viewport.zoom < 4) iconSize = 0.7;
    if (viewport.zoom < 3.5) iconSize = 0.6;
    if (viewport.zoom < 3) iconSize = 0.5;
    if (viewport.zoom < 2.5) iconSize = 0.4;
    if (viewport.zoom < 2) iconSize = 0.3;
    if (viewport.zoom < 1.5) iconSize = 0.2;

    if (iconSize !== this.state.iconSize) {
      this.setState({ iconSize });
    }
  };

  onMetalMetroSelected = (metro) => {
    const mapConfig = {
      maxZoom: 30,
      duration: 0.5,
      paddingTopLeft: [30, 30],
      paddingBottomRight: [30, 30],
    };

    const positions = [metro.coordinates.toLeaflet()];

    this.map.leafletElement.flyToBounds(positions, {
      ...mapConfig,
    });
  };

  componentDidUpdate(prev) {
    const { isSidebarCollapsed, isInfoOpen } = this.props;
    if (prev.isSidebarCollapsed !== isSidebarCollapsed || prev.isInfoOpen !== isInfoOpen) {
      setTimeout(() => {
        this.map.leafletElement.invalidateSize({ pan: true, animate: true });
      }, 251);
    }
  }

  getMapZoom = () => this.map?.leafletElement.getZoom();

  render() {
    const {
      projectId,
      phaseId,
      project,
      phase,
      selected,
      isInfoOpen,
      metros = [],
      connectionType,
      setConnectionType,
      layers = {},
      toggleLayer,
      loading,
      ringLatency,
      ringColor,
      setRingLatency,
      setRingColor,
      availableRegion,
      setAvailableRegion,
      metroType,
      setMetroType,
      toggleAvailableRegions,
      onMetroPanelTabChange,
      platform,
      platformMetros,
      product,
      onClose,
      isSidebarCollapsed,
      openMapMenu,
      changeOpenMapMenu,
      mapType,
      marketplaceSegment,
      onChangeMapType,
      onChangeMarketplaceSegment,
      isEditing,
      setInfraComponentCard,
      infraComponentCard,
      toggleAllLabels,
    } = this.props;

    const locations = getLocations(phase, layers);
    const regions = getServiceRegions(phase, selected);
    const activeMetros = filterMetrosByType(phase.metros, metroType);
    const directConnections = phase.directConnections;
    const { iconSize, contextMenuPosition, mapUrl, selectedMapStyle } = this.state;

    const maxBounds = isInfoOpen
      ? [
          [90, -180],
          [-90, 334],
        ]
      : [
          [90, -180],
          [-90, 180],
        ];

    return (
      <section className={cn('map', { isSidebarCollapsed, 'panel-open': isInfoOpen })}>
        <LeafletMap
          data-testid={'map'}
          className={cn({ 'old-map-background': selectedMapStyle === 'oldMapStyle' })}
          animate
          useFlyTo
          zoomControl={false}
          boundsOptions={{
            maxZoom: 10,
            duration: 0.8,
          }}
          minZoom={2}
          maxZoom={10}
          maxBounds={maxBounds}
          onContextMenu={this._contextMenu}
          onViewportChanged={this._onViewportChange}
          ref={(m) => (this.map = m)}>
          <ZoomControl position="topright" />
          <TileLayer
            url={mapUrl}
            attribution={
              '<a href="https://www.maptiler.com/copyright/" target="_blank">© MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">© OpenStreetMap contributors</a>'
            }
            bounds={maxBounds}
            noWrap
            tileSize={selectedMapStyle === 'newMapStyle' ? 512 : 256}
            zoomOffset={selectedMapStyle === 'newMapStyle' ? -1 : 0}
          />
          <LayersMenu
            connectionType={connectionType}
            setConnectionType={setConnectionType}
            infraComponentCard={infraComponentCard}
            setInfraComponentCard={setInfraComponentCard}
            layers={layers}
            toggleLayer={toggleLayer}
            toggleAvailableRegions={toggleAvailableRegions}
            ringLatency={ringLatency}
            setRingLatency={setRingLatency}
            ringColor={ringColor}
            setRingColor={setRingColor}
            availableRegion={availableRegion}
            setAvailableRegion={setAvailableRegion}
            metroType={metroType}
            setMetroType={setMetroType}
            isLayersMenuOpen={openMapMenu === 'layers'}
            changeOpenMapMenu={changeOpenMapMenu}
            changeMapStyle={this.changeMapStyle}
            selectedMapStyle={selectedMapStyle}
            toggleAllLabels={toggleAllLabels}
          />
          <MarketplaceButton
            position={'topright'}
            open={openMapMenu === 'marketplace'}
            changeOpenMapMenu={changeOpenMapMenu}
            marketplaceSegment={marketplaceSegment}
            onChangeMarketplaceSegment={onChangeMarketplaceSegment}
            mapType={mapType}
            onChangeMapType={onChangeMapType}
          />
          <ExportButton
            project={project}
            phase={phase}
            connectionType={connectionType}
            layers={layers}
            ringColor={ringColor}
            ringLatency={ringLatency}
            marketplaceSegment={marketplaceSegment}
            mapType={mapType}
          />
          {contextMenuPosition && (
            <ContextMenu onClose={this._contextMenuClose} position={contextMenuPosition}>
              <button onClick={this._createLocation('OFFICE', contextMenuPosition)}>Add a Customer Site</button>
              <button onClick={this._createLocation('DATACENTER', contextMenuPosition)}>Add a Customer Data Center</button>
              <button onClick={this._onManageMetros}>Manage Metros</button>
            </ContextMenu>
          )}
          {mapType && <MarketplaceLayer mapType={mapType} marketplaceSegment={marketplaceSegment} metros={metros} zoom={this.getMapZoom()} />}
          {layers.inactiveMetros &&
            metros?.map((metro) => (
              <Marker
                key={metro.id}
                position={metro.coordinates.toLeaflet()}
                icon={getIcon('metro', iconSize)}
                onClick={() => this._addMetro(phaseId, metro)}
                zIndexOffset={200}>
                {!loading && (
                  <Tooltip pane={'tooltipPane'} direction="top" offset={[0, -5]}>
                    {!activeMetros.find((am) => am.id.equals(metro.id)) && 'Enable'} {metro.name}
                  </Tooltip>
                )}
              </Marker>
            ))}
          {layers.customerLocations &&
            phase.sortedGroups.map(([color, locations]) => (
              <MarkerGroup key={color} data={locations.map((l) => l.coordinates.toLeaflet())} color={color} iconSize={iconSize} />
            ))}
          {activeMetros.map((metro) => {
            return (
              <ActiveMetro
                key={metro.id}
                projectId={project.id}
                phaseId={phaseId}
                metro={metro}
                selected={selected}
                onSelect={this._onSelect}
                iconSize={iconSize}
                layers={layers}
                noText={!layers.latencies}
                ringLatency={ringLatency}
                ringColor={ringColor}
                onMetroPanelTabChange={onMetroPanelTabChange}
                onClose={onClose}
                marketplaceSegment={marketplaceSegment}
                mapType={mapType}
                isEditing={isEditing}
                infraComponentCard={infraComponentCard}
                zoom={this.getMapZoom()}
              />
            );
          })}
          {platform && (
            <PlatformEquinixOverlay
              platformMetros={platformMetros}
              projectId={project.id}
              phaseId={phaseId}
              onSelect={this.onMetalMetroSelected}
              iconSize={iconSize}
              product={product}
              platform={platform}
            />
          )}
          <Clusterer
            key={`cluster-${phaseId}-${this.getMapZoom()}-${layers.customerLocations}`}
            iconSize={iconSize}
            projectId={project.id}
            phaseId={phaseId}
            customPaneZIndex={1000}>
            {layers.customerLocations &&
              locations.map((location) => (
                <PhaseLocation
                  key={location.id}
                  projectId={project.id}
                  phaseId={phaseId}
                  location={location}
                  selected={selected}
                  onSelect={this._onSelect}
                  iconSize={iconSize}
                  onClose={onClose}
                  isEditing={isEditing}
                  locationLabels={layers.locationLabels}
                />
              ))}
          </Clusterer>
          {regions
            .filter((region) => !region.region.deprecated || (region.region.deprecated && region.enabled))
            .map((spec) => (
              <ConnectedServiceRegionMarker
                projectId={projectId}
                phaseId={phaseId}
                service={spec.service}
                key={spec.region.id}
                region={spec.region}
                selectedItem={selected}
                selected={spec.selected}
                enabled={spec.enabled}
                connections={spec.connections}
                metros={metros}
                layers={layers}
                iconSize={iconSize}
              />
            ))}
          {selected instanceof Service &&
            selected.regions.length > 0 &&
            selected.regions.map((region) => (
              <ServiceRegionMarker
                service={selected}
                key={region.id}
                region={region}
                enabled={this.props.selectedRegions.find((r) => r.equals(region))}
                onEnableRegion={this.props.onEnableRegion}
                onDisableRegion={this.props.onDisableRegion}
                layers={layers}
                iconSize={iconSize}
              />
            ))}
          {layers.connections && (
            <MetroConnections noText={!layers.latencies} connectionType={connectionType} metros={metros} activeMetros={activeMetros} iconSize={iconSize} />
          )}
          {layers.directConnections &&
            directConnections.map((dm) => {
              const fromMetro = dm.source;
              return dm.connectedTo.map((dc) => {
                return (
                  <DirectConnection
                    key={`${fromMetro} => ${dc.metro}`}
                    fromMetro={fromMetro}
                    toMetro={dc.metro}
                    text={dc.label}
                    color={dc.color}
                    iconSize={iconSize}
                  />
                );
              });
            })}
        </LeafletMap>
        {loading && (
          <div className="saving-toast">
            <div className="spinner"></div>
            <p>Updating...</p>
          </div>
        )}
      </section>
    );
  }
}

ProjectMap = withAddMetro(ProjectMap);
ProjectMap = withAddLocation(ProjectMap);

export default ProjectMap;
