import React, { useState } from 'react';
import { Marker, Tooltip } from 'react-leaflet';
import { dismissAllToasts } from 'utils/Toast';
import withDeleteProjectItems from '../PhasePanel/hocs/withDeleteProjectItems';
import ContextMenu from './ContextMenu';
import { getIcon } from './icons';
import cn from 'classnames';

const PhaseLocation = ({ location, isEditing, selected, onSelect, loading, iconSize = 1, locationLabels, deleteProjectItems, projectId, phaseId, onClose }) => {
  const [contextMenu, setContextMenu] = useState(false);
  const type = location.type.value.toLowerCase();

  const onDelete = async () => {
    setContextMenu(true);
    if (selected?.id === location.id) {
      onClose();
    }
    await deleteProjectItems(projectId, phaseId, [location.id]);
    dismissAllToasts();
  };

  const _onContextMenu = () => {
    if (!isEditing) return;
    setContextMenu(true);
  };

  return (
    <Marker
      key={`${location.id}-${locationLabels}`}
      location={location}
      position={location.coordinates.toLeaflet()}
      icon={getIcon(type, iconSize, selected && location.id.equals(selected.id))}
      onClick={() => onSelect(type, location)}
      onContextMenu={_onContextMenu}
      opacity={loading ? 0.8 : 1}
      zIndexOffset={300}>
      <Tooltip
        permanent={locationLabels}
        className={cn('smallIcon-tooltip', { smallLabel: iconSize < 1 }, { smallestLabel: iconSize < 0.5 })}
        key={`${location.id}-${locationLabels}`}
        pane={'tooltipPane'}
        direction="top"
        offset={[0, -18]}>
        <div>{location.name ? location.name : location.address.label}</div>
      </Tooltip>
      {contextMenu && (
        <ContextMenu onClose={() => setContextMenu(false)}>
          <button onClick={onDelete}>Delete Site</button>
        </ContextMenu>
      )}
    </Marker>
  );
};

export default withDeleteProjectItems(PhaseLocation);
