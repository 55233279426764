import React from 'react';
import Select from 'react-select';
import ReactTooltip from 'react-tooltip';
import { getTheme } from 'utils/SelectTheme';
import cn from 'classnames';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

import ToggleButton from 'views/components/ToggleButton';
import MapButton from '../MapButton';
import Checkbox from 'views/components/Checkbox';
import {
  connectionTypes,
  metroOptions,
  minRingLatency,
  maxRingLatency,
  ringColorOptions,
  availableRegionsOptions,
  infraComponentOptions,
} from './selectOptions';
import InfoIcon from 'views/components/icons/Info';
import { sliderMarks, sliderStyle } from './sliderConfig';
import StringUtils from 'utils/StringUtils';
import DropdownStyles from 'views/components/DropdownStyles';
import newMapStyle from '../../../../static/img/new-map-style.jpg';
import oldMapStyle from '../../../../static/img/old-map-style.jpg';

export default class LayersMenu extends React.Component {
  componentDidUpdate() {
    ReactTooltip.rebuild();
  }
  render() {
    const {
      connectionType,
      setConnectionType,
      ringLatency,
      setRingLatency,
      ringColor,
      setRingColor,
      availableRegion,
      setAvailableRegion,
      metroType,
      setMetroType,
      layers,
      toggleLayer,
      toggleAvailableRegions,
      isLayersMenuOpen,
      changeOpenMapMenu,
      setInfraComponentCard,
      infraComponentCard,
      changeMapStyle,
      selectedMapStyle,
      toggleAllLabels,
    } = this.props;
    const {
      activeMetros,
      inactiveMetros,
      customerLocations,
      availableRegions,
      services,
      latencies,
      serviceLatencies,
      connections,
      latencyRings,
      directConnections,
      labels,
      customerLocationLines,
      metroLabels,
      locationLabels,
      regionLabels,
      infraComponents,
    } = layers;

    const onLatencyChange = (e) => {
      const nonNumRegex = /[^0-9]/g;
      const startingZeroRegex = /^0+/;

      let value = e?.target?.value ?? e;

      value = value.toString().replace(startingZeroRegex, '').replace(nonNumRegex, '');

      if (value > maxRingLatency) {
        value = maxRingLatency;
      } else if (value < minRingLatency || !value || StringUtils.isWhitespace(value)) {
        value = minRingLatency;
      }
      setRingLatency(value);
    };

    const onInputKeyDown = (e) => {
      const invalidChars = ['-', 'e', '+', 'E'];
      if (invalidChars.includes(e.key)) {
        e.preventDefault();
      }
    };

    return (
      <MapButton
        data-testid={'layers-button'}
        className={cn('layers-button', { open: isLayersMenuOpen })}
        position="topright"
        text="Map Layers"
        onClick={() => changeOpenMapMenu('layers')}>
        {isLayersMenuOpen && (
          <div className="layersMenu" onClick={(e) => e.stopPropagation()}>
            <div className="layersWrapper">
              <ul>
                <div className="section-item">
                  <div className="section-title">
                    <h6>Map Style</h6>
                  </div>
                </div>
                <div className="imagesWrapper">
                  <img
                    alt="New Map Style"
                    src={newMapStyle}
                    className={cn('image', { selected: selectedMapStyle === 'newMapStyle' })}
                    onClick={() => changeMapStyle('newMapStyle')}
                  />
                  <img
                    alt="Old Map Style"
                    src={oldMapStyle}
                    className={cn('image', { selected: selectedMapStyle === 'oldMapStyle' })}
                    onClick={() => changeMapStyle('oldMapStyle')}
                  />
                </div>
              </ul>
              <div className="horizontal-line" />
              <ul>
                <li onClick={toggleLayer('customerLocations')} className="clickable-item section-title">
                  <h6> Customer Locations </h6>
                  <ToggleButton checked={customerLocations} onToggled={toggleLayer('customerLocations')} />
                </li>
                <div className={cn('dropdown-section', { visible: customerLocations })}>
                  <div className="section-inline">
                    <li onClick={toggleLayer('customerLocationLines')} className="clickable-item">
                      <span className="subsection-title">Show Connections</span>
                      <Checkbox checked={customerLocations && customerLocationLines} onCheck={toggleLayer('customerLocationLines')} />
                    </li>
                  </div>
                </div>
              </ul>
              <div className="horizontal-line" />

              <ul>
                <li onClick={toggleLayer('activeMetros')} className="clickable-item section-title">
                  <h6> Equinix Metros </h6>
                  <ToggleButton checked={activeMetros} onToggled={toggleLayer('activeMetros')} />
                </li>
                <div className={cn('dropdown-section', { visible: activeMetros })}>
                  <div className="section-inline">
                    <li>
                      <span className="subsection-title">Display:</span>
                      <div className="select-wrapper">
                        <Select
                          classNamePrefix="select"
                          value={metroOptions.find((i) => i.value === metroType)}
                          onChange={(e) => setMetroType(e.value)}
                          options={metroOptions}
                          isSearchable={false}
                          theme={getTheme}
                          menuPlacement={'auto'}
                          menuPosition={'fixed'}
                          styles={DropdownStyles}
                          autosize={true}
                        />
                      </div>
                    </li>
                  </div>

                  <div className="section-inline">
                    <li onClick={toggleLayer('inactiveMetros')} className="clickable-item">
                      <span className="subsection-title">Hide Unused Metros</span>
                      <Checkbox checked={activeMetros && !inactiveMetros} onCheck={toggleLayer('inactiveMetros')} />
                    </li>
                  </div>
                  <div className="section-item">
                    <li>
                      <div className="column">
                        <div className="section-inline clickable-item" onClick={toggleLayer('latencyRings')}>
                          <span className="subsection-title">Latency Rings</span>
                          <Checkbox checked={activeMetros && latencyRings} onCheck={toggleLayer('latencyRings')} />
                        </div>
                        {latencyRings && (
                          <>
                            <div className="ring-radius-wrapper">
                              <div className="section-item section-select-group">
                                <span className="subsection-title">- Ring Radius</span>
                                <div className="milliseconds">
                                  <input
                                    className="latency-ms-input"
                                    type="number"
                                    min={minRingLatency}
                                    max={maxRingLatency}
                                    onChange={onLatencyChange}
                                    onKeyDown={onInputKeyDown}
                                    value={ringLatency}></input>
                                </div>
                              </div>
                            </div>
                            <div className="section-item section-select-group">
                              <div className="select-wrapper range">
                                <Slider dots step={1} min={0} max={40} onChange={onLatencyChange} value={ringLatency} marks={sliderMarks} {...sliderStyle} />
                              </div>
                            </div>
                            <div className="ing-color-wrapper">
                              <div className="section-item section-select-group">
                                <span className="subsection-title">- Ring Color</span>
                                <div className="select-wrapper">
                                  <Select
                                    classNamePrefix="select"
                                    value={ringColorOptions.find((i) => i.value === ringColor)}
                                    onChange={(e) => setRingColor(e.value)}
                                    options={ringColorOptions}
                                    isSearchable={false}
                                    theme={getTheme}
                                    menuPlacement={'auto'}
                                    menuPosition={'fixed'}
                                    styles={DropdownStyles}
                                  />
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </li>
                  </div>
                </div>
              </ul>
              <div className="horizontal-line" />
              <ul>
                <div className="main-section-wrapper">
                  <li onClick={toggleLayer('connections')} className="clickable-item section-title">
                    <h6>Fabric Connections</h6>
                    <ToggleButton checked={connections} onToggled={toggleLayer('connections')} />
                  </li>
                </div>
                <div className={cn('dropdown-section', { visible: connections })}>
                  <div className="section-inline">
                    <li>
                      <span className="subsection-title">Display as:</span>
                      <div className="select-wrapper">
                        <Select
                          classNamePrefix="select"
                          value={connectionTypes.find((i) => i.value === connectionType)}
                          onChange={(e) => setConnectionType(e.value)}
                          options={connectionTypes}
                          isSearchable={false}
                          theme={getTheme}
                          menuPlacement={'auto'}
                          menuPosition={'fixed'}
                          styles={DropdownStyles}
                        />
                      </div>
                    </li>
                  </div>
                  <div className="section-inline">
                    <li onClick={toggleLayer('latencies')} className="clickable-item">
                      <span className="subsection-title">Show Latency</span>
                      <Checkbox checked={latencies && connections} onCheck={toggleLayer('latencies')} />
                    </li>
                  </div>
                </div>
              </ul>
              <div className="horizontal-line" />
              <ul>
                <li onClick={toggleLayer('directConnections')} className="clickable-item section-title">
                  <h6> Direct Connections </h6>
                  <ToggleButton checked={directConnections} onToggled={toggleLayer('directConnections')} />
                </li>
              </ul>
              <div className="horizontal-line" />
              <ul>
                <li onClick={toggleLayer('services')} className="clickable-item section-title">
                  <h6> Service Tiles </h6>
                  <ToggleButton checked={services} onToggled={toggleLayer('services')} />
                </li>
              </ul>

              <div className="horizontal-line" />

              <ul>
                <li onClick={toggleLayer('infraComponents')} className="clickable-item section-title">
                  <h6> Infrastructure Components </h6>
                  <ToggleButton checked={infraComponents} onToggled={toggleLayer('infraComponents')} />
                </li>
                <div className={cn('dropdown-section', { visible: infraComponents })}>
                  <div className="section-inline">
                    <li>
                      <span className="subsection-title">Show as:</span>
                      <div className="select-wrapper">
                        <Select
                          classNamePrefix="select"
                          value={infraComponentOptions.find((i) => i.value === infraComponentCard)}
                          onChange={(e) => setInfraComponentCard(e.value)}
                          options={infraComponentOptions}
                          isSearchable={false}
                          theme={getTheme}
                          menuPlacement={'auto'}
                          menuPosition={'fixed'}
                          styles={DropdownStyles}
                        />
                      </div>
                    </li>
                  </div>
                </div>
              </ul>
              <div className="horizontal-line" />

              <ul>
                <li onClick={toggleAvailableRegions('availableRegions')} className="clickable-item section-title">
                  <h6 className="subsection-title">CSP Availability Regions</h6>
                  <ToggleButton checked={availableRegions} onToggled={toggleAvailableRegions('availableRegions')} />
                </li>
                <div className={cn('dropdown-section', { visible: availableRegions })}>
                  <div className="section-inline">
                    <li>
                      <span className="subsection-title">Display:</span>
                      <div className="select-wrapper">
                        <Select
                          classNamePrefix="select"
                          value={availableRegionsOptions.find((i) => i.value === availableRegion)}
                          onChange={(e) => setAvailableRegion(e.value)}
                          options={availableRegionsOptions}
                          isSearchable={false}
                          theme={getTheme}
                          menuPlacement={'auto'}
                          menuShouldScrollIntoView={true}
                          menuPosition={'fixed'}
                          styles={DropdownStyles}
                        />
                      </div>
                    </li>
                  </div>
                  <div className="section-inline clickable-item" onClick={toggleLayer('serviceLatencies')}>
                    <span className="subsection-title" data-tip data-for="serviceLatencies-disclaimer">
                      Show Estimated Latency
                      <InfoIcon width={24} height={24} viewBox={`0 0 24 24`} color="#333333" className="info-icon" />
                    </span>
                    <Checkbox checked={serviceLatencies && toggleAvailableRegions} onCheck={toggleLayer('serviceLatencies')} />
                  </div>
                </div>
              </ul>
              <ReactTooltip id="serviceLatencies-disclaimer" place="left" type="dark" effect="solid" multiline>
                <p>Values are indicative only of relative distance.</p>
                <p>Actual testing at an Equinix SVC or equivalent is strongly suggested for any other purpose.</p>
              </ReactTooltip>

              <div className="horizontal-line" />
              <ul>
                <li onClick={() => toggleAllLabels()} className="clickable-item section-title">
                  <h6 className="subsection-title">Show Labels</h6>
                  <ToggleButton checked={labels || metroLabels || locationLabels || regionLabels} onToggled={() => toggleAllLabels()} />
                </li>
                <div className={cn('dropdown-section', { visible: labels || metroLabels || locationLabels || regionLabels })}>
                  <div className="section-inline">
                    <li onClick={toggleLayer('metroLabels')} className="clickable-item">
                      <span className="subsection-title">Metro Names</span>
                      <Checkbox checked={metroLabels} onCheck={toggleLayer('metroLabels')} />
                    </li>
                  </div>
                  <div className="section-inline">
                    <li onClick={toggleLayer('labels')} className="clickable-item">
                      <span className="subsection-title">Custom Labels</span>
                      <Checkbox checked={labels} onCheck={toggleLayer('labels')} />
                    </li>
                  </div>
                  <div className="section-inline">
                    <li onClick={toggleLayer('locationLabels')} className="clickable-item">
                      <span className="subsection-title">Customer Location Labels</span>
                      <Checkbox checked={locationLabels} onCheck={toggleLayer('locationLabels')} />
                    </li>
                  </div>
                  <div className="section-inline">
                    <li onClick={toggleLayer('regionLabels')} className="clickable-item">
                      <span className="subsection-title">Availability Regions</span>
                      <Checkbox checked={regionLabels} onCheck={toggleLayer('regionLabels')} />
                    </li>
                  </div>
                </div>
              </ul>
            </div>
          </div>
        )}
      </MapButton>
    );
  }
}
